import React, { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import { Box } from "@mui/material";
import Filters from "../../components/filters/filters.component";
import BigCard from "../../components/big-card/big-card.component";
import Section from "../../components/section/section.component";
import Footer from "../../components/footer/footer.component";
import axios from "axios";
import PendingExperimentsCard from "../../components/experiments/pending-experiments-card/pending-experiments-card.component";
import RunningExperimentsCard from "../../components/experiments/running-experiments-card/running-experiments-card.component";
import ExperimentsSection from "../../components/experiments/experiments-section/experiments-section.component";
import { getUserInfo } from "../../services/userInfo.service";
import { PullToRefreshContainer } from "../../utils/PullToRefreshElements";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { API_BASE_URL } from "../../config/api.config";

const MyExperiments = () => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const now = new Date(); // Fecha actual
  const isSummerTime = now.getTimezoneOffset() === -120; // CEST es UTC+2 (offset de -120 minutos)
  const centralEuropeanTimeZoneOffset = isSummerTime
    ? 2 * 60 * 60 * 1000
    : 1 * 60 * 60 * 1000; // 2 horas si es horario de verano, 1 hora si es estándar
  const currentDate = new Date(now.getTime() + centralEuropeanTimeZoneOffset);
  const [metricsData, setMetricsData] = useState({});
  const [pendingExperiments, setPendingExperiments] = useState([]);
  const [runningExperiments, setRunningExperiments] = useState([]);
  const [incomingExperiments, setIncomingExperiments] = useState([]);
  const [bigCardSubtitle, setBigCardSubtitle] = useState("");
  // eslint-disable-next-line
  const [period, setPeriod] = useState("w");

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `${API_BASE_URL}/experiments?clientId=${encodeURIComponent(userInfo.customer_id)}&group=${encodeURIComponent(filters.groups)}&period=w&storeClusterId=${encodeURIComponent(filters.clusters)}&location=${encodeURIComponent(filters.locations)}&category=${encodeURIComponent(filters.categories)}&storeId=${encodeURIComponent(filters.machines)}`
        );
        setMetricsData(data);
        setPendingExperiments(
          data?.experiments?.filter(
            (element) =>
              element?.status?.status === "pending" &&
              element.incoming === false
          )
        );
        setRunningExperiments(
          data?.experiments?.filter(
            (element) =>
              element?.status?.status === "running" &&
              element.incoming === false
          )
        );
        setIncomingExperiments(
          data?.experiments?.filter((element) => element?.incoming)
        );
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    }
  };
  useEffect(
    () => {
      if (loginToken) {
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
        } else {
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
            }
          }, 100);
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  const sliderSettings = {
    spaceBetween: -15,
    slidesPerView: 1.06,
  };
  // HARDCODED FAKE DATA //
  const bigCardFirstSlider = {
    title: "Ingresos Incrementales",
    subtitle: bigCardSubtitle,
    headline: "+€ Experimentos",
  };
  const bigCardSecondSlider = {
    title: "Unidades Incrementales",
    subtitle: bigCardSubtitle,
    headline: "+U Experimentos",
  };

  // END OF HARDCODED FAKE DATA //
  return (
    <PullToRefreshContainer onRefresh={fetchMetrics}>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Experimentos</div>
          </div>
        }
        avatar="FB"
        longTitle
      />
      <Box
        sx={{
          marginBottom: "90px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Filters
          disableFirstMachineSelected
          setBigCardSubtitle={setBigCardSubtitle}
          filters={filters}
          setFilters={setFilters}
        />
        <Swiper {...sliderSettings}>
          <SwiperSlide>
            <BigCard
              // sprintProgress={70}
              bigCardData={bigCardFirstSlider}
              metricsData={metricsData}
              logo="experiment"
              experimentsPage
              slideIndex="0"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BigCard
              // sprintProgress={70}
              bigCardData={bigCardSecondSlider}
              metricsData={metricsData}
              logo="experiment"
              experimentsPage
              slideIndex="1"
            />
          </SwiperSlide>
        </Swiper>
        {/* RUNNING EXPERIMENTS SMALL CARD START */}
        {runningExperiments?.length > 0 && (
          <ExperimentsSection title="Impacto Experimentos" header="c" />
        )}
        {runningExperiments?.map((element) => {
          const periodEnd = new Date(element?.period?.end);
          if (periodEnd > currentDate) {
            return <RunningExperimentsCard key={element?.id} {...element} />;
          } else {
            return null;
          }
        })}
        {/* RUNNING EXPERIMENTS SMALL CARD END */}

        {/* PENDING EXPERIMENTS START */}
        {pendingExperiments?.length > 0 && (
          <Section title="Experimentos a Accionar" />
        )}
        <Swiper {...sliderSettings}>
          {pendingExperiments?.map((element) => {
            return (
              <SwiperSlide key={element?.id}>
                <PendingExperimentsCard
                  fetchMetrics={fetchMetrics}
                  {...element}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* PENDING EXPERIMENTS END  */}

        {/* RUNNING EXPERIMENTS START */}
        {runningExperiments?.length > 0 && (
          <Section title="Experimentos en curso" />
        )}
        <Swiper {...sliderSettings}>
          {runningExperiments?.map((element) => {
            const periodEnd = new Date(element?.period?.end);
            if (periodEnd > currentDate) {
              return (
                <SwiperSlide key={element?.id}>
                  <PendingExperimentsCard
                    fetchMetrics={fetchMetrics}
                    {...element}
                  />
                </SwiperSlide>
              );
            } else {
              return null;
            }
          })}
        </Swiper>
        {/* RUNNING EXPERIMENTS END */}

        {/* INCOMING EXPERIMENTS START */}
        {incomingExperiments?.length > 0 && (
          <Section title="Experimentos Futuros" />
        )}
        <Swiper {...sliderSettings}>
          {incomingExperiments?.map((element) => {
            return (
              <SwiperSlide key={element?.id}>
                <PendingExperimentsCard
                  fetchMetrics={fetchMetrics}
                  {...element}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* INCOMING EXPERIMENTS END */}
      </Box>
      <Footer activeIconID={2} />
    </PullToRefreshContainer>
  );
};

export default MyExperiments;
