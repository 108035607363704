// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { getUserInfo } from "../../services/userInfo.service";
import { CustomSelect } from "./CustomSelect.component";
import { API_BASE_URL } from "../../config/api.config";

const Filters = ({
  setBigCardSubtitle,
  machinesSelectorDisabled,
  filters,
  setFilters,
  salesFilter,
  disableFirstMachineSelected,
}) => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const [newDropdownData, setNewDropdownData] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allMachines, setAllMachines] = useState([]);
  const [allClusters, setAllClusters] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  //Logic to fetch dropdowns data
  useEffect(
    () => {
      const fetchDropdowns = async () => {
        try {
          if (userInfo) {
            const { data } = await axios.get(
              `${API_BASE_URL}/dd-all?clientId=${encodeURIComponent(userInfo.customer_id)}`
            );
            setNewDropdownData(data);
            setAllGroups(data.map((group) => group.name));
          }
        } catch (error) {
          // Error handler
          console.error("Error 500 en la solicitud de dropdowns:", error);
        }
      };

      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchDropdowns();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchDropdowns();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [loginToken]
  );

  //Logic to set big card subtitle when setBigCardSubtitle setter is pased as prop. Plus the logic to select the first group of the list when the machinesFilter prop is pased.
  useEffect(
    () => {
      if (setBigCardSubtitle) {
        let result = allMachines.filter((element) => {
          return element.id === filters.machines;
        });
        setBigCardSubtitle(result?.[0]?.alias);
      }
      if (
        allGroups.length > 0 &&
        filters.groups === "default"
      ) {
        const event = { target: { name: "groups", value: allGroups[0] } };
        handleFilters(event);
      }
    },
    // eslint-disable-next-line
    [setBigCardSubtitle, allMachines, filters.machines, allGroups]
  );

  const handleFilters = (event) => {
    const { name, value } = event.target;

    // create a new object to store the new filters after selection
    let newFilters = { ...filters };

    // debugging purposes
    // console.log(`hf: dropdown <${name}> value <${value}>`);

    // LOGIC
    // ----------------
    // we want to update the dropdowns options depending on the selected value & the selected dropdown
    // if we select
    // - group: we want to update the clusters, categories & machines
    // - cluster: we want to update the categories & machines
    // - category: we want to update the machines
    //
    // Always calculate the available machines for the current filters and select the first one as default

    // helper functions to get the group, cluster & category
    const getGroup = (name) => { return newDropdownData.find((group) => group.name === name); };
    const getCluster = (group, name) => { return group.positions.find((position) => position.name === name); };
    const getCategory = (position, name) => { return position.categories.find((category) => category.name === name); };

    // helper function to sort & remove duplicates
    const sort = (values) => [...new Set(values)].sort();

    // helper function to parse the selected values and return the available positions, categories & machines
    const parseValues = (f) => {
      let values = {
        positions: [],
        categories: [],
        machines: [],
      };

      // helper function to check if a dropdown is selected
      const selected = (dd) => f[dd] !== "default";

      // if there's no group selected, return the default values
      if (!selected('groups')) return values;

      // get the selected group
      const group = getGroup(f.groups);
      const position = group && selected('clusters') ? getCluster(group, f.clusters) : null;
      const category = position && selected('categories') ? getCategory(position, f.categories) : null;

      // get positions
      values.positions = group.positions.map((position) => position.name);
      // get categories
      values.categories = (
        position
          ? position.categories.map((category) => category.name)
          : group.positions.flatMap((position) => position.categories).map((category) => category.name)
      );
      // get machines
      if (category){
        // selected position & category
        values.machines = category.machines;
      } else if (selected('clusters')) {
        // selected position but no category
        values.machines = position.categories.flatMap((category) => category.machines);
      } else if (selected('categories')) {
        // selected category but no position
        values.machines = (
          group.positions
            .flatMap((position) => position.categories)
            .filter((category) => category.name === f.categories)
            .flatMap((category) => category.machines)
        );
      } else {
        // all group machines
        values.machines = group.positions.flatMap((position) => position.categories).flatMap((category) => category.machines);
      }
      return values;
    };

    // eslint-disable-next-line
    switch (name) {
      case "groups":
        newFilters = {
          ...filters,
          groups: value,
          clusters: "default",
          categories: "default",
          machines: "default",
        };
        break;
      case "clusters":
        newFilters = {
          ...filters,
          clusters: value,
          categories: "default",
          machines: "default",
        };
        break;
      case "categories":
        newFilters = {
          ...filters,
          categories: value,
          machines: "default",
        };
        break;
      case "machines":
        newFilters = {
          ...filters,
          machines: value,
        };
        break;
    }
    // get the available values for the selected group, cluster & category
    const values = parseValues(newFilters);

    // debugging purposes
    // console.log(`hf: machines <${JSON.stringify(values.machines)}>`);

    // Set the new values
    setAllClusters(sort(values.positions));
    setAllCategories(sort(values.categories));
    setAllMachines(sort(values.machines));

    // update default machine (if it's not set) but don't set the first machine if salesFilter prop is passed.
    if (
      !disableFirstMachineSelected &&
      newFilters.machines === "default" &&
      values.machines.length > 0
    ) {
      newFilters = { ...newFilters, machines: values.machines[0].id };
    } else {
      newFilters = { ...newFilters };
    }

    // log & update the filters values
    setFilters(newFilters);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        maxWidth: "552px",
        backgroundColor: "#F8F8F8",
        alignContent: "center",
        paddingInline: "1rem",
        height: "2.5rem",
        // position: "fixed",
        zIndex: "1",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="groups"
          value={filters.groups}
          onChange={handleFilters}
          items={allGroups}
          label="Grupo"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="clusters"
          value={filters.clusters}
          onChange={handleFilters}
          items={allClusters}
          label="Posición"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="categories"
          value={filters.categories}
          onChange={handleFilters}
          items={allCategories}
          label="Tipo"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        {machinesSelectorDisabled ? null : (
          <CustomSelect
            name="machines"
            value={filters.machines}
            onChange={handleFilters}
            items={allMachines}
            label="Máquinas"
            isMachines={true}
            disabled={machinesSelectorDisabled}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Filters;
