import React, { useEffect, useState } from "react";
import { Box, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Header from "./header.component";
import ReactMarkdown from "react-markdown";
import { API_BASE_URL } from "../../config/api.config";

function Promos() {
  const [promos, setPromos] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get("clientId");
  const storeId = queryParams.get("storeId");

  const formatDatesRange = (dateFrom, dateTo) => {
    const formatDate = (date) => {
      if (!date) return "-/-";
      const formattedDate = new Date(date);
      const day = formattedDate.getDate().toString().padStart(2, "0");
      const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
      return `${day}/${month}`;
    };

    const formattedFrom = formatDate(dateFrom);
    const formattedTo = formatDate(dateTo);

    return (
      <>
        Promoción válida desde:
        {<span style={{ fontWeight: "bold" }}> {formattedFrom}</span>} al
        {<span style={{ fontWeight: "bold" }}> {formattedTo}</span>}.
      </>
    );
  };

  useEffect(() => {
    if (clientId && storeId) {
      const fetchPromos = async () => {
        let { data } = await axios.get(
          `${API_BASE_URL}/promotions?clientId=${clientId}&storeId=${storeId}`
        );
        setPromos(data);
      };
      fetchPromos();
    }
  }, [clientId, storeId]);

  return (
    <Box sx={{ backgroundColor: "#663B73", minHeight: "100vh" }}>
      <CssBaseline />
      <Header title={"Novedades Vending de la Semana"} />
      <Grid container>
        {promos
          ?.slice()
          .reverse()
          .map((promo) => {
            if (promo.active) {
            }
            return (
              <Grid
                key={promo.id}
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "200px",
                  marginBlock: "1rem",
                }}
              >
                <Paper
                  elevation={10}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "1rem",
                    padding: "1rem"
                  }}
                >
                  <Typography fontWeight="bold" variant="h5">
                    {promo.title}
                  </Typography>
                  <Typography fontWeight="bold" variant="subtitle1">
                    {promo.subtitle}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }} variant="body1">
                    <ReactMarkdown>{promo.message}</ReactMarkdown>
                  </Typography>
                  <Typography variant="body2" sx={{ marginBlockStart: "1rem" }}>
                    {formatDatesRange(promo.validFrom, promo.validTo)}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBlockStart: "1rem",
          }}
        >
          <Grid item xs={8}>
            <Typography color="#EA572E" fontWeight="bold" variant="h5">
              ¡Buscá los productos marcados con Stickers!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Promos;
